import React from 'react'
import clsx from 'clsx'
import {
  pageSearchData,
  useBlogArchiveMetadata,
} from '@hooks/blog-archive-metadata'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Breadcrumbs from '@components/seo/Breadcrumbs'
import BlogArchiveSearch from '@components/pages/blog/archive/search'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'
import SocialConnectCards from '@components/global/cards/SocialConnectCards'

import * as cx from './PageBlogSearch.module.scss'

const PageBlogSearch = () => {
  const query = useBlogArchiveMetadata()

  return (
    <LayoutInnerPage>
      <Seo
        description={query.blog.seo.metaDesc}
        seo={query.blog.seo}
        title={query.blog.seo.title}
      />
      <div className={clsx(cx.wrapper)}>
        <div className="container">
          <Breadcrumbs
            wrapperClassName={clsx(cx.breadcrumbs)}
            data={pageSearchData.breadcrumbs}
          />
        </div>
        <BlogArchiveSearch
          postsPerPage={9}
          title="Blog Search Results"
          subtitle="For “agent image website”"
          placeholder="Agent Image Website"
          reverseHeading={true}
          redirectSearch={false}
        />
      </div>
      <LeadContactForm
        version="LCF04"
        modelOrBackground="Jada"
        title="Free Consultation"
        subtitle="Get in touch with us for a"
        submitLabel="I want a stunning website!"
        leadName="Blog Search"
        layout="A"
      />
      <SocialConnectCards
        // wrapperClassName={cx.social}
        heading={pageSearchData.heading}
        subheading={pageSearchData.subheading}
      />
    </LayoutInnerPage>
  )
}

export default PageBlogSearch
